import { IObjectFieldProps } from "../index";
import Button from "@mui/material/Button";
import { ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import * as api from "@crochik/pi-api";
import { EditFormDialog } from "./EditFormDialog";

export function FormObjectField(props: IObjectFieldProps) {
    const { form, field, value } = props;
    const { isReadOnly: formReadOnly } = form;
    const { isReadOnly: fieldReadOnly } = field;
    const isReadOnly = formReadOnly || fieldReadOnly;
    const [formModel, setFormModel] = useState<api.Form>();
    const [isPreviewVisible, showPreview] = useState(false);

    useEffect(() => {
        const form = api.FormFromJSONTyped(value, false);
        setFormModel(form); // toAPIModel(value) as api.Form
    }, [value]);

    const onPreview = () => {
        if (!formModel) return;

        showPreview(true);
        // App().pushFormDialog({
        //     key: `${field.name}_Preview`,
        //     form: Form.create(formModel, null),
        //     onActionAsync: async (dialog: IFormDialog, action?: Action, lastResult?: IDataFormActionResponse) => {
        //         if ((action?.action ?? action?.name) === "#close") {
        //             return false;
        //         }
        //
        //         // do nothing
        //         // ...
        //         return true;
        //     }
        // });
    };

    const onClosePreview = () => {
        showPreview(false);
    };

    if (!formModel) {
        if (isReadOnly) return null;
        return (
            <div>
                <Button>Add</Button>
            </div>
        );
    }

    const { name, title, fields } = formModel;

    return (
        <>
            <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                <div>{title ?? name}</div>
                {!!title && name !== title && <div>{name}</div>}
                {fields && <div>{fields.length} Field(s)</div>}
                {/*{actions && <div>{actions.length} Action(s)</div>}*/}

                <ButtonGroup>
                    <Button onClick={onPreview}>Preview</Button>
                    {!isReadOnly && <Button>Edit</Button>}
                </ButtonGroup>
            </div>
            {isPreviewVisible && <EditFormDialog onClose={onClosePreview} form={formModel} raw={value!} />}
        </>
    );
}