import { Alert, Chip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Operator } from "@crochik/pi-api";
import { Form } from "src/pi/ui/Form";
import DataService, { IReferenceValue } from "../../../../services/DataService";
import { Loading } from "../../Loading";
import { RequestError } from "../../../../api/Client";
import Section from "../Section";
import { AppointmentField } from "../AppointmentField";
import * as api from "@crochik/pi-api";
import { options } from "sanitize-html";

interface IProps {
    form: Form;
    field: api.FormField;
    value: any;
    onClick: (e: React.MouseEvent) => any;
}

function StandardReadOnlyReferenceField({ field, form, value }: IProps) {
    const [selected, setSelected] = useState<IReferenceValue[]>();
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(true);

    const options = field.options as api.ReferenceFieldOptions;
    const optionsType = options["_t"] ?? options.t;
    const isMultiSelect = optionsType === "MultiReferenceFieldOptions";

    useEffect(() => {
        if (form.isDesigning) {
            setSelected([
                {
                    id: field.name!,
                    value: field.name!,
                }
            ]);
            setLoading(false);
            return;
        }

        if (!value) {
            // no value
            setSelected(undefined);
            setLoading(false);
            return;
        }

        if (isMultiSelect) {
            if (!Array.isArray(value)) {
                setError("Value is not array");
                return;
            }
            if (value.length < 1) {
                // multiselect without any children
                setSelected(undefined);
                setLoading(false);
                return;
            }
        }

        const { items } = options;
        const objectType = form?.getObjectTypeForReferenceField(options);

        if (!!items && !isMultiSelect && !!items[value]) {
            // single select and value is in items
            setSelected(items[value]);
            setLoading(false);
            return;
        }

        if (!objectType) {
            setError("Bad config");
            setLoading(false);
            return;
        }

        setLoading(true);

        const criteria = form?.updateCriteria(options.criteria);
        criteria.push({
            fieldName: "#id",
            operator: isMultiSelect ? Operator.In : Operator.Eq,
            value,
        });

        DataService()
            .lookupAsync(objectType, { criteria, lookupField: options.foreignFieldName })
            .then((rows) => {
                if (isMultiSelect) {
                    const found: { [name: string]: string } = {};
                    rows.forEach(x => {
                        found[x.id] = x.value;
                    });
                    value.forEach(x => {
                        if (x in found) return;
                        if (!!items && x in items) {
                            found[x] = items[x];
                            return;
                        }
                        found[x] = x;
                    });
                    setSelected(Object.keys(found).map(x => {
                        return {
                            id: x,
                            value: found[x]
                        };
                    }));

                } else {
                    if (rows.length === 1) {
                        setSelected(rows);
                    } else {
                        setSelected([{
                            id: value, 
                            value,
                        }]);
                    }
                }

                setLoading(false);
            })
            .catch((ex) => {
                if (ex instanceof RequestError) {
                    if (ex.json && "message" in ex.json) {
                        setError(ex.json["message"] as string);
                        setLoading(false);
                        return;
                    }
                }

                const reason = ex as any;
                setError(typeof reason === "string" ? reason : reason["status"]);
                setLoading(false);
            });
    }, [value, options, field.name, form, isMultiSelect]);


    if (error) {
        return (
            <Alert severity="error">{error}</Alert>
        );
    }

    if (isLoading) {
        return (
            <div style={{ width: 100, height: 24, display: "flex", alignItems: "center" }}>
                <Loading />
            </div>
        );
    }

    if (!selected || selected.length === 0) return null;

    if (selected.length > 1) {
        return (
            <div>
                {selected?.map(x => <Chip
                    key={x.id}
                    label={x.value}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                />)}
            </div>
        )
    }

    return (
        <div>
            <span>{selected[0].value}</span>
        </div>
    );
}


export function ReadOnlyReferenceField(props: IProps) {
    const { field, form, value } = props;
    const options = field.options as api.ReferenceFieldOptions;

    const optionsType = options['_t'] ?? options.t;
    if (optionsType === "AppointmentFieldOptions") {
        return (
            <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
                <AppointmentField field={field} value={value} form={form} />
            </Section>
        );
    }

    // TODO: move away from hardcoding, use a different fieldoptions type
    // ...
    switch (options?.objectType) {
        // case "Appointment":
        //     return (
        //         <Section title={field.label ?? field.name ?? ""} expanded={!!value}>
        //             <AppointmentField field={field} value={value} form={form} />
        //         </Section>
        //     );

        // case "UnlayerTemplate":
        //     return titleWithValue(
        //         <div key={field.name} style={{ paddingTop: 6, paddingBottom: 6 }}>
        //             <UnlayerTemplateField field={field} value={value} form={form} disabled={true} />
        //         </div>
        //     );
    }

    if (!value) return null;

    return <StandardReadOnlyReferenceField {...props} />
}