import { Alert, Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "../../../Form";
import { DataViewComponent } from "../../DataViewComponent/DataViewComponent";
import { FormBody } from "../../FormBody/FormBody";
import { Loading } from "../../Loading";
import { FieldProps } from "../FieldProps";
import { DiscriminatedObjectForm } from "../ObjectField/DiscriminatedObjectForm";
import { Default } from "../../../../context/AppContext";
import { observable, toJS } from "mobx";
import { Dump } from "../../../../Dump";
import * as api from "@crochik/pi-api";
import Section from "../Section";

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TYPE } from "src/pi/context/IForm";

interface IProps extends FieldProps {
    form?: Form;
}

export function ChildrenField(props: IProps) {
    const { field, form, value } = props;
    const { isReadOnly: fieldReadOnly } = field;
    const { isReadOnly: formReadOnly } = form ?? {};
    const isReadOnly = formReadOnly || fieldReadOnly;

    const [formComponents, setFormComponents] = useState<{ [key: string]: Form }>();
    const [error, setError] = useState<string>();
    const [url, setUrl] = useState<string>();
    const [keyValue, setKeyValue] = useState<string>("NEW");
    const [keyForm, setKeyForm] = useState<Form>();
    const [isAdding, setAdding] = useState<boolean>(false);

    const options: api.ChildrenFieldOptions = field.options as api.ChildrenFieldOptions;
    const { editForms, addFormUrls, keyType } = options;

    const isArray = keyType === "int";
    const apiName = field.apiName ?? field.name;

    useEffect(() => {
        if (!isReadOnly) {
            return;
        }

        const values = form?.values;
        if (!values) {
            setError("Missing form");
            return;
        }

        if (!options.url) {
            // setError(`Missing url: ${JSON.stringify(values)}`);
            return;
        }

        let id: string | undefined;
        if ("_id" in values) {
            id = values["_id"] as string;
        } else if ("Id" in values) {
            id = values["Id"] as string;
        } else if ("id" in values) {
            id = values["id"] as string;
        }

        const url = options.url.indexOf("{{id}}") < 0 ? options.url : options.url.replace("{{id}}", !!id ? id : "MISSING_ID");
        setUrl(url);
    }, [form, options.url, isReadOnly]);

    useEffect(() => {
        if (isReadOnly || !form) return;

        if (editForms) {
            // create forms
            const indices = Object.keys(editForms);
            const formComps: { [key: string]: Form } = {};
            indices.forEach((x, i) => {
                const config = editForms[x];
                if (!config.title) config.title = config.name;

                const apiName = field.apiName ?? field.name;
                config.name = `${form.name}.${apiName}.${x}`;
                const formComp = Form.create(config as api.Form, null);
                formComps[x] = formComp;
            });
            setFormComponents(formComps);
        }

        if (addFormUrls && !isArray) {
            const f: api.Form = {
                name: `${form.name}_${apiName}_NEWKEY`,
                fields: [
                    // TODO: check options in case a different field type is defined
                    // ...
                    {
                        t: TYPE.TEXT,
                        type: TYPE.TEXT,
                        name: 'Key',
                        label: 'New Key',
                        isRequired: true,
                    }
                ]
            };
            setKeyForm(Form.create(f, null));
        }

    }, [isReadOnly, editForms, addFormUrls, apiName, isArray, field.apiName, field.name, form]);

    useEffect(() => {
        if (!isAdding || !form?.name) return;

        // init parent container with an observable
        const apiName = field.apiName ?? field.name;
        const parent = `${form.name}.${apiName}`;
        if (!Form.getValues(parent)) {
            const placeHolder = isArray ? observable([]) : observable({});
            Default.state.set(parent, placeHolder, "form");
        }

    }, [isAdding, field, isArray, form?.name]);

    const addChild = (kv) => {
        const newChildContext = `${form?.name}.${apiName}.${kv}`;
        const lastForm = Form.get(newChildContext);
        if (lastForm) {
            const values = toJS(lastForm.values);
            console.log('add child', kv, values);
            const newFormComponents = {
                ...formComponents
            };
            newFormComponents[kv] = lastForm;
            setFormComponents(newFormComponents);
        }
    }

    const onFinishAdding = () => {
        if (!isAdding) return;

        addChild(keyValue);

        setAdding(false);
    };

    const onCancelAdding = () => {
        setAdding(false);
    }

    const onNew = () => {
        const newKey = `${formComponents ? Object.keys(formComponents).length : 0}`;
        setKeyValue(newKey);
        setAdding(true);
    };

    const onAddKey = () => {
        if (!keyForm) return;
        const value = keyForm.getValue("Key");
        if (!value || value.length<1) {
            // error...
            return;
        }
        if (formComponents && value in formComponents) {
            // error...
            return;
        }

        keyForm.resetValues();
        setKeyValue(value);
        setAdding(true);

        // addChild(value);
    }

    if (!form) {
        return <Alert severity="error">Missing form</Alert>;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (isReadOnly) {
        if (!options.url) {
            return (
                <div>
                    <Dump
                        style={{
                            margin: 0,
                            padding: 0,
                            fontSize: "10pt",
                            color: "gray"
                        }}
                        object={value}
                    />
                </div>
            );
        }

        if (!url) {
            return <Loading />;
        }

        const { style } = field;
        const computedStyle = style || {
            border: "1px solid rgba(0,0,0,.26)",
            // backgroundColor: disabled ? "rgb(239, 239, 239)" : "white",
            // borderRadius: 8,
            height: "100%",
            position: "relative"
        };

        return (
            <div
                style={{
                    ...computedStyle,
                    overflow: "hidden"
                }}
            >
                <DataViewComponent view={url} overriddenTitle={field.label ?? field.name!} />
            </div>
        );
    }

    return (
        <>
            <div style={{ paddingBottom: 6, paddingTop: 6, overflow: "auto", maxHeight: '50vh' }}>
                {
                    formComponents && Object.keys(formComponents).sort().map((x, i) => (
                        <Section title={isArray ? `#${i + 1}: ` : x} expanded={false} key={x}>
                            <FormBody form={formComponents[x]} key={x} />
                        </Section>
                    ))
                }
            </div>

            {addFormUrls && isAdding && (
                <Section title={isArray ? `#${parseInt(keyValue)+1} (NEW)` : `${keyValue} (NEW)`} expanded={true}>
                    <DiscriminatedObjectForm {...options} context={`${form?.name}.${apiName}.${keyValue}`} form={form} />
                    <IconButton onClick={onFinishAdding}><CheckIcon /></IconButton>
                    <IconButton onClick={onCancelAdding}><CancelIcon /></IconButton>
                </Section>
            )}

            {
                addFormUrls && !isAdding && keyForm && !isArray && (
                    <div>
                        <FormBody form={keyForm} />
                        <IconButton onClick={onAddKey}><AddIcon /></IconButton>
                    </div>
                )
            }

            {addFormUrls && !isAdding && isArray && (
                <IconButton onClick={onNew}><AddIcon /></IconButton>
            )}
        </>
    );
}

