import { Alert, FormControl, InputLabel, Popover } from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Operator } from "@crochik/pi-api";
import DataService, { Condition, IReferenceValue } from "../../../../services/DataService";
import { Form } from "../../../Form";
import { Loading } from "../../Loading";
import { AutocompleteBase, AutocompleteBaseProps } from "../Autocomplete/AutocompleteBase";
import { AutocompleteReadonly } from "../Autocomplete/AutocompleteReadonly";
import { FieldProps } from "../FieldProps";
import { parseResponseError } from "../../../../api/Client";
import * as api from "@crochik/pi-api";

export function getAutoCompleteCriteria(field: api.FormField, form?: Form, value?: string): Condition[] {
    const options = field.options as api.ReferenceFieldOptions;
    const { criteria } = options;

    const qcriteria = criteria ? [...criteria] : [];

    var addOrUpdate = (key: string, value?: any) => {
        if (!value) return;
        var existing = qcriteria.find((x) => x.fieldName === key);
        if (existing) {
            existing.operator = Operator.In;
            existing.value = value;
        } else {
            qcriteria.push({
                fieldName: key,
                operator: Operator.In,
                value,
            });
        }
    };

    addOrUpdate("#autocomplete", value);

    return form?.updateCriteria(qcriteria) ?? qcriteria;
}

export function addAdditionalItems(field: api.FormField, rows: IReferenceValue[], filter?: string) {
    const options = field.options as api.ReferenceFieldOptions;
    const { items } = options;

    filter = filter?.toLowerCase();

    if (items && Object.keys(items).length > 0) {
        for (var option of Object.keys(items)) {
            const value = items[option];
            if (!!filter && value.toLowerCase().indexOf(filter) < 0) continue;

            const refValue: IReferenceValue = {
                id: option,
                value,
            };
            rows.push(refValue);
        }
    }
}

function MultiReferenceFieldF(props: IProps) {
    const { value, field, style, form, displayInline, autoOpen, onChange } = props;
    const options = field.options as api.ReferenceFieldOptions;
    const objectType = form?.getObjectTypeForReferenceField(options);

    const [isOpen, setOpen] = useState<boolean>(autoOpen ?? false);
    const [isLoading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>();
    const [searchStr, setSearchStr] = useState<string>();
    const [selectedMap, setSelectedMap] = useState<{ [id: string]: IReferenceValue }>({});
    const [rows, setRows] = useState<IReferenceValue[]>();
    const anchorRef = useRef<HTMLDivElement>(null);
    const [pendingValue, setPendingValue] = useState<string[]>(value);

    const lookup = useCallback(async (criteria: Condition[]) => {
        if (!objectType || !options) {
            setError("Missing properties");
            return [];
        }

        let rows = await DataService()
            .lookupAsync(objectType, {
                criteria,
                lookupField: options.foreignFieldName,
            });

        addAdditionalItems(field, rows);
        return rows;
    }, [field, objectType, options]);

    const fetchCurrentValues = useCallback(async (value?: any) => {
        setLoading(true);

        try {
            const criteria = [
                {
                    fieldName: "#id",
                    operator: Operator.In,
                    value,
                },
            ];

            const valueRows = await lookup(criteria);
            const map: { [id: string]: IReferenceValue } = {};
            valueRows.forEach(x => map[x.id] = x);
            setSelectedMap(map);
            setLoading(false);

        } catch (ex) {
            parseResponseError(ex).then(setError);
            setLoading(false);
        }
    }, [lookup]);

    const requestIndex = useRef(0);
    const search = useCallback(async (searchStr?: string) => {
        try {
            requestIndex.current++;
            const index = requestIndex.current;
            setRows(undefined);

            const criteria = getAutoCompleteCriteria(field, form, searchStr);
            const result = await lookup(criteria);

            if (requestIndex.current === index) {
                setRows(result);
            }

        } catch (ex) {
            parseResponseError(ex).then(setError);
        }
    }, [field, form, lookup]);

    const onOpen = useCallback(async () => {
        if (!rows) {
            await search();
        }
        setOpen(true);
    }, [rows, search]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        setSearchStr(event.target.value);
        search(event.target.value);
    };

    const onClosePopover = () => {
        setOpen(false);

        if (!displayInline) {
            onChange?.(field, pendingValue);
        }
    }

    useEffect(() => {
        if (form?.isDesigning) return;

        let needToLoad = false;
        if (Array.isArray(value)) {
            value.forEach(x => {
                if (!selectedMap || !(x in selectedMap)) {
                    needToLoad = true;
                }
            });
        }
        if (!needToLoad) return;
        fetchCurrentValues(value);
    }, [value, selectedMap, form?.isDesigning, fetchCurrentValues]);

    useEffect(() => {
        if (!isOpen) return;
        onOpen();
    }, [isOpen, onOpen]); // do not add onOpen here or will cause issues!??!?!?

    const onValueChange = (newValue: string[]) => {
        if (!displayInline) {
            setPendingValue(newValue);
            return;
        }
        onChange?.(field, newValue);
    };

    const onClear = () => {
        onChange?.(field, null);
    }

    const inputLabelProps = {
        required: field.isRequired,
        error: !!error,
        htmlFor: field.name!,
        shrink: true,
    };

    const renderField = () => {
        if (error) {
            return <Alert severity="error">error</Alert>;
        }

        if (isLoading) {
            return <Loading />
        }

        const autocompleteBaseProps: AutocompleteBaseProps = {
            rows,
            value,
            onInputChange: onInputChange,
            onValueChange: onValueChange,
            label: field.label || field.name || "",
            loading: isLoading,
            selectedMap,
            searchStr: searchStr ?? "",
        };

        if (displayInline) {
            return <AutocompleteBase {...autocompleteBaseProps} />;
        }

        return (
            <>
                <div ref={anchorRef} className={css(styles.anchor)}>
                    <AutocompleteReadonly {...autocompleteBaseProps} onOpen={onOpen} onClear={onClear} />
                </div>
                {isOpen && anchorRef.current &&
                    <Popover
                        open={isOpen}
                        anchorEl={anchorRef.current}
                        onClose={onClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <AutocompleteBase {...autocompleteBaseProps} />
                    </Popover>
                }
            </>
        )
    }

    return (
        <div
            style={{
                display: "flex",
                paddingTop: 16,
                paddingBottom: 8,
                ...style,
            }}
            key={field.name}
        >
            <FormControl style={{ width: "100%" }}>
                {!options?.autoComplete && (
                    <>
                        <InputLabel {...inputLabelProps}>{field.label ?? field.name!}</InputLabel>
                        <br />
                    </>
                )}
                {renderField()}
            </FormControl>
        </div>
    );
}

const styles = StyleSheet.create({
    anchor: {
        cursor: "pointer",
    },
});

interface IProps extends FieldProps {
    form?: Form;
    displayInline?: boolean;
    autoOpen?: boolean;
}

@observer
export class MultiReferenceField extends React.Component<IProps> {
    render(): React.ReactNode {
        const props = toJS(this.props);
        return <MultiReferenceFieldF {...props} />
    }
}
