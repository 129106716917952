import EmailIcon from '@mui/icons-material/AlternateEmail';
import SelectIcon from '@mui/icons-material/ArrowDropDownCircle';
import CalculateIcon from '@mui/icons-material/Calculate';
import DateIcon from '@mui/icons-material/CalendarToday';
import CheckboxIcon from "@mui/icons-material/CheckBox";
import ReferenceIcon from "@mui/icons-material/Commit";
import ObjectIcon from '@mui/icons-material/DataObject';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddressIcon from '@mui/icons-material/Email';
import DateTimeIcon from '@mui/icons-material/Event';
import TextIcon from "@mui/icons-material/FormatColorText";
import LabelIcon from '@mui/icons-material/TextFieldsRounded';
import UrlIcon from "@mui/icons-material/Link";
import RelatedObjectsIcon from "@mui/icons-material/ListAlt";
import TagsIcon from '@mui/icons-material/LocalOffer';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NumberIcon from "@mui/icons-material/Numbers";
import PhoneIcon from '@mui/icons-material/Phone';
import OtherIcon from '@mui/icons-material/QuestionMark';
import TimeIcon from '@mui/icons-material/Schedule';
import PostalCodeIcon from '@mui/icons-material/Signpost';
import ViewListIcon from '@mui/icons-material/ViewList';
import HiddenIcon from "@mui/icons-material/VisibilityOff";
import { TYPE } from "src/pi/context/IForm";
import FunctionsIcon from '@mui/icons-material/Functions';
import DataArrayIcon from '@mui/icons-material/DataArray';
import GenericIcon from '@mui/icons-material/HdrAuto';
import DictionaryIcon from '@mui/icons-material/ImportContactsRounded';
import BitwiseIcon from '@mui/icons-material/TuneRounded';
import DistanceIcon from '@mui/icons-material/ArchitectureRounded';

export function FieldTypeIcon({ type }: { type?: string; }) {
    switch (type) {
        case TYPE.CHECKBOX:
            return <CheckboxIcon />;
        case TYPE.HIDDEN:
            return <HiddenIcon />;
        case TYPE.NUMBER:
            return <NumberIcon />;
        case TYPE.REFERENCE:
        case TYPE.MULTIREFERENCE:
            return <ReferenceIcon />;
        case TYPE.RELATEDOBJECTS:
            return <RelatedObjectsIcon />;
        case TYPE.TEXT:
            return <TextIcon />;
        case TYPE.URL:
            return <UrlIcon />;
        case TYPE.DATE:
            return <DateIcon />;
        case TYPE.DATETIME:
            return <DateTimeIcon />;
        case TYPE.DATERANGE:
            return <DateRangeIcon />;
        case TYPE.TIME:
            return <TimeIcon />;
        case TYPE.TAGS:
            return <TagsIcon />;
        case TYPE.OBJECT:
            return <ObjectIcon />;
        case TYPE.CHILDREN:
            return <ViewListIcon />;
        case TYPE.EMAIL:
            return <EmailIcon />;
        case TYPE.ADDRESS:
            return <AddressIcon />;
        case TYPE.PHONE:
            return <PhoneIcon />;
        case TYPE.SELECT:
        case TYPE.MULTISELECT:
            return <SelectIcon />;
        case TYPE.POSTALCODE:
        case "postalCode":
            return <PostalCodeIcon />;
        case TYPE.CALCULATED:
            return <CalculateIcon />;
        case TYPE.LOCATION:
            return <MyLocationIcon />;
        case TYPE.EXPRESSION:
            return <FunctionsIcon />;
        case TYPE.ARRAY:
            return <DataArrayIcon />;
        case TYPE.GENERIC:
            return <GenericIcon />;
        case TYPE.DICTIONARY:
            return <DictionaryIcon />;
        case TYPE.BITWISEFLAG:
            return <BitwiseIcon />;
        case TYPE.LOCATIONDISTANCE:
            return <DistanceIcon />;
        case TYPE.LABEL:
            return <LabelIcon />;

        // DICTIONARY = 'dictionary',
        // FILE = 'file',
        // LABEL = 'label',
        // LOOKUP = 'lookup',
        // PASSWORD = 'password',
        // POSTALCODE = 'postalcode',
        // APPOINTMENT
    }

    return <div><OtherIcon /><span>{type}</span></div>
}
